import React, { useState } from "react";
import axios from "axios";
import { FaTimes } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { useGlobalContext } from "../context/context";
import { ColorRing } from "react-loader-spinner";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";

const schema = yup.object({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  email: yup.string().email().required(),
  password: yup.string().min(6).max(14).required(),
  confirmpassword: yup
    .string()
    .required("Please confirm your password")
    .oneOf([yup.ref("password"), null], "Password must matched"),
});

const Register = () => {
  const [phone, setPhone] = useState("");
  const [loading, setLoading] = useState(false);

  const { baseUrl } = useGlobalContext();

  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const getData = async (data) => {
    setLoading(true);
    data.phone = phone;

    axios
      .post(`${baseUrl}auth/register`, data)
      .then((data) => {
        if (data.status === 201) {
          setLoading(false);
          toast.success("Account Creation Successful");
          setTimeout(() => {
            navigate("/auth/login");
          }, 2000);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        if (error.response.data.keyPattern.email === 1) {
          toast.error("User Already Exist", { theme: "dark" });
        }
      });
  };

  return (
    <section className="parent-con">
      <div className="register-con">
        <div>
          <Link className="link" to="/">
            <FaTimes />
          </Link>
        </div>
        <h3>Create an Account</h3>
        <p>
          It takes less than a minute. If you already have an account.
          <Link to="/auth/login" className="reset_text">
            login
          </Link>
        </p>
        {/* Registration Form */}
        <form
          className="login-form"
          onSubmit={handleSubmit((data) => getData(data))}
        >
          <div>
            <p>Email*</p>
            <input
              type="email"
              {...register("email", { required: "Email is required" })}
            />
            <p className="login-error-message">{errors.email?.message}</p>
          </div>
          <div>
            <p>First Name*</p>
            <input
              type="text"
              {...register("firstName", { required: "First Name is required" })}
            />
            <p className="login-error-message">{errors.firstName?.message}</p>
          </div>
          <div>
            <p>Last Name*</p>
            <input
              type="text"
              {...register("lastName", { required: "First Name is required" })}
            />
            <p className="login-error-message">{errors.lastName?.message}</p>
          </div>
          <div>
            <p>Phone*</p>
            <PhoneInput value={phone} onChange={setPhone} />
            <p className="login-error-message">
              {phone?.length === "" && "Phone is required"}
            </p>
          </div>
          <div>
            <p style={{ marginBottom: 20 }}>Country*</p>
            <input
              type="text"
              {...register("country", { required: "First Name is required" })}
            />
          </div>
          <div>
            <p>Password*</p>
            <input
              type="password"
              {...register("password", {
                required: "Password must be greater than 8 digits",
              })}
            />
            <p className="login-error-message">{errors.password?.message}</p>
          </div>
          <div>
            <p>Confirm Password*</p>
            <input
              type="password"
              {...register("confirmpassword", {
                required: "Confirm Password must be number",
              })}
            />
            <p className="login-error-message">
              {errors.confirmpassword?.message}
            </p>
          </div>
          <div className="terms-and-condition">
            <input type="checkbox" />
            <Link to={"/terms"}>Accept the terms and condition's</Link>
          </div>
          <button className="btn">
            <span> Register</span>
            {loading && (
              <ColorRing
                visible={true}
                height="40"
                width="40"
                ariaLabel="blocks-loading"
                wrapperStyle={{}}
                wrapperClass="blocks-wrapper"
                colors={["#fff", "#fff", "#fff", "#fff", "#fff"]}
              />
            )}
          </button>
        </form>
        <ToastContainer />
      </div>
    </section>
  );
};

export default Register;
