import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Button from "../Button/Button";
import "./Nav.css";
import { RxHamburgerMenu } from "react-icons/rx";
import { navItems } from "../../utils/data";
import logoText from "../../assets/logo.svg";

const Nav = ({ tab, toggleNav }) => {
  const navigate = useNavigate();
  return (
    <nav>
      <ul className="nav">
        <Link to={"/about"}>About</Link>
        <Link to={"/proof-of-reserve"}>Proof of Reserve</Link>
        <Link to={"/wallet"}>Wallet</Link>
        <Link to={"/exchange"}>Exchange</Link>
        <Link to={"/auth/login"}>Sign In</Link>
        <Button
          color={"#fff"}
          background={"#2a3bd5"}
          height={50}
          title={"Sign Up"}
          fontSize={16}
          width={120}
          action={() => navigate("/auth/register")}
        />
      </ul>
      <div className={`tabs_sect ${tab ? "open_nav" : ""}`}>
        <div className="nav_list">
          <div className="header_item_one">
            <RxHamburgerMenu onClick={toggleNav} size={24} color="black" />
            <img src={logoText} alt="" />
          </div>
          <div>
            {navItems.map((item, index) => {
              const { items } = item;
              return (
                <div className="lists" key={index}>
                  <p>{item.name}</p>
                  {items.map((list, indx) => {
                    const { src, name, path } = list;
                    return (
                      <Link
                        onClick={toggleNav}
                        to={`/${path}`}
                        className="tabs"
                        key={indx}
                      >
                        {src}
                        <p>{name}</p>
                      </Link>
                    );
                  })}
                </div>
              );
            })}
          </div>
        </div>
        <div className="transparent_bg"></div>
      </div>
    </nav>
  );
};

export default Nav;
