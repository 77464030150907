import React from "react";

import { GoLocation } from "react-icons/go";
import facebook from "../../assets/facebook.svg";
import instagram from "../../assets/instagram.svg";
import twitter from "../../assets/twitter.svg";
import "./Footer.css";
import logoText from "../../assets/logo.svg";
import Motion from "../../apps/Motion";
import MotionX from "../../apps/MotionX";
import MotionRight from "../../apps/MotionRight";
import { AiOutlineMail } from "react-icons/ai";
import { Link } from "react-router-dom";

const Footer = () => {
  const name = "Cryptomax Expert";

  return (
    <footer id="contact">
      <section className="footer_links">
        <div className="footer_links_item_one">
          <Motion>
            <img src={logoText} alt="" />
          </Motion>
          <MotionX>
            <p>
              Welcome to {name}, your gateway to the world of cryptocurrency
              trading. Our platform offers expert guidance and cutting-edge
              tools to help you navigate the exciting realm of digital assets
              with confidence.
            </p>
          </MotionX>
        </div>
        <div className="footer_links_item_two">
          <MotionX>
            <h3>Quick Links</h3>
          </MotionX>
          <MotionRight>
            <Link to={"/about"}>About us</Link>
          </MotionRight>
          <Motion>
            <Link to={"/privacy"}>Privacy policy</Link>
          </Motion>
          <MotionRight>
            <Link to={"/terms"}>Terms of service</Link>
          </MotionRight>
        </div>
        <div className="footer_links_item_three">
          <Motion>
            <h3>Contact us</h3>
          </Motion>
          <MotionRight>
            <div className="flex">
              <GoLocation size={18} color="white" style={{ width: "10%" }} />
              <p>Location: 135 Main Street, Lincoln</p>
            </div>
          </MotionRight>
          <MotionX>
            <div className="flex">
              <AiOutlineMail size={18} color="white" style={{ width: "10%" }} />
              <p>
                <a href="mailto:support@cryptomaxexpert.online">
                  support@cryptomaxexpert.online
                </a>
              </p>
            </div>
          </MotionX>
        </div>
      </section>
      <section className="footer_contact">
        <MotionX>
          <p>© All Rights Reserved. 2018, {name}</p>
        </MotionX>
        <div className="footer_icon_section">
          <Motion>
            <img src={facebook} alt="" />
          </Motion>
          <MotionRight>
            <img src={instagram} alt="" />
          </MotionRight>
          <MotionX>
            <img src={twitter} alt="" />
          </MotionX>
        </div>
      </section>
    </footer>
  );
};

export default Footer;
