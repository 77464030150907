import axios from "axios";
import React, { useContext, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
const AppContext = React.createContext();

const AppProvider = ({ children }) => {
  // const baseUrl = "http://localhost:5000/api/";
  const baseUrl = "https://cryptomax-expert-api.onrender.com/api/";
  const user = JSON.parse(sessionStorage.getItem("user"));
  const _id = user?._id;
  const [depositHistory, setDepositHistory] = useState([]);
  const [withdrawHistory, setWithdrawHistory] = useState([]);
  const [userDetails, setUserDetails] = useState([]);
  const [userLoading, setUserLoading] = useState(false);
  const [btcLoading, setBtcLoading] = useState(false);

  const getUser = (token, id) => {
    setUserLoading(true);
    axios
      .get(`${baseUrl}users/${id || user?._id}`, {
        headers: { token },
      })
      .then((data) => {
        if (data.status === 200) {
          setUserLoading(false);
          setUserDetails(data.data);
          sessionStorage.setItem("user", JSON.stringify(data.data));
        }
      })
      .catch((error) => {});
  };

  const getAllDeposits = (accessToken) => {
    axios
      .get(`${baseUrl}deposit/${_id}`, {
        headers: { token: accessToken },
      })
      .then((data) => {
        setDepositHistory(data.data.deposit);
        sessionStorage.setItem(
          "deposits",
          JSON.stringify(
            data.data.deposit
              .filter((deposits) => deposits.status === true)
              .reduce((accumulator, { amount }) => accumulator + amount / 1, 0)
          )
        );
      })
      .catch((error) => {});
  };
  const getAllWithdrawals = (accessToken) => {
    axios
      .get(`${baseUrl}transfer/${_id}`, {
        headers: { token: accessToken },
      })
      .then((data) => {
        setWithdrawHistory(data.data.transfers);
        sessionStorage.setItem(
          "withdraw",
          JSON.stringify(data.data.totalAmountTransferred)
        );
      })
      .catch((error) => {});
  };

  const getPendingDepositsAmount = () => {
    return depositHistory
      .filter((deposit) => deposit.status === false)
      .reduce((accumulator, { amount }) => accumulator + amount / 1, 0);
  };

  const getAllWithdrawalss = (token) => {
    axios
      .get(`${baseUrl}transfer`, {
        headers: { token: token },
      })
      .then((data) => {})
      .catch((error) => {});
  };

  return (
    <AppContext.Provider
      value={{
        baseUrl,
        setUserDetails,
        userDetails,
        getUser,
        userLoading,
        getAllDeposits,
        btcLoading,
        setBtcLoading,
        depositHistory,
        getPendingDepositsAmount,
        getAllWithdrawals,
        withdrawHistory,
        getAllWithdrawalss,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

const useGlobalContext = () => {
  return useContext(AppContext);
};

export { useGlobalContext, AppProvider };
